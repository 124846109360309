@import '../../base/base';
.widget-content-area, .dataTables_wrapper {
  padding: 0;
}

div.dataTables_wrapper button:hover {
  -webkit-transform: none;
  transform: none;
}

.inv-list-top-section {
  margin: 20px 21px 20px 21px;
}

div.dataTables_wrapper div.dataTables_length {
  align-self: center;

  label {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.dataTables_wrapper .dataTables_length select.form-control {
  margin: 0;
}

div.dataTables_wrapper div.dataTables_filter {
  align-self: center;

  svg {
    top: 10px;
  }

  label {
    margin: 0;
    margin-right: 15px;
  }

  input {
    margin: 0;
  }
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
}

table.dataTable {
  margin: 0 !important;
}

.table > {
  thead {
    border-top: none;
    border-bottom: none;

    > tr > th {
      text-transform: initial;
      font-weight: 600;
      border-top: none;
      border-right: none;
      border-left: none;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      -webkit-transition: all 0.1s ease;
      transition: all 0.1s ease;
      padding: 10px 21px 10px 21px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      white-space: nowrap;

      &:first-child {
        &:before, &:after {
          display: none;
        }
      }

      &:last-child {
        &:before, &:after {
          display: none;
        }
      }
    }
  }

  tbody {
    &:before {
      display: none;
    }

    > tr {
      > td {
        &:first-child {
          border-top-left-radius: 8px;
        }

        padding: 0;
        padding: 10px 21px 10px 21px;
        letter-spacing: normal;
        white-space: nowrap;

        .inv-number {
          color: $primary;
          cursor: pointer;
          font-size: 16px;
          text-align: left;
        }

        .user-name {
          color: $dark;
          font-size: 14px;
          letter-spacing: .14px;
          margin-bottom: 0;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;
        }

        .inv-email {
          color: #506690;
          font-size: 14px;
          letter-spacing: .14px;
          margin-bottom: 0;
          margin-top: 0;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;

          svg {
            width: 17px;
            height: 17px;
            vertical-align: text-bottom;
            color: $secondary;
            stroke-width: 1.5;
          }
        }

        .inv-date svg {
          width: 17px;
          height: 17px;
          vertical-align: text-top;
          color: $info;
          stroke-width: 1.5;
        }

        .dropdown {
          .dropdown-toggle svg {
            stroke-width: 1px;
          }

          &.show .dropdown-toggle svg {
            stroke-width: 1px;
            color: #7367f0;
          }

          &:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
            padding: 9px !important;
          }
        }
      }

      &:last-child > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu, &:nth-last-child(2) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
        top: -94px !important;
      }

      &:last-child > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show, &:nth-last-child(2) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show {
        top: -90px !important;
      }

      > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        vertical-align: text-top;
      }
    }
  }
}

/* 
    Inv List Bottom Section
*/

.inv-list-bottom-section {
  padding: 15px;
}